import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import Video from "../components/atoms/Video"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/privacy-tech-003.png"
import GetInTouch from "../components/molecules/GetInTouch"
import vid from "../../static/videos/privacy-tech-vid.mp4"


function PrivacyTech() {
  return (
    <>
      <Seo
        title="Decoding the new generation of privacy technologies | Craig Walker x Meta"
        image={thumb}
        description="The digital advertising ecosystem is evolving as users demand more privacy, as regulations expand and mobile operating systems and browsers are updated to respond to this new environment."
      />

      <Splash
        text={<>Decoding the new generation of privacy technologies</>}
        work={true}
      >
        <p>
          <b>
            The digital advertising ecosystem is evolving as users demand more
            privacy, as regulations expand and mobile operating systems and
            browsers are updated to respond to this new environment.
          </b>
        </p>
        <p>
          Meta is developing new forms of digital advertising which are less
          reliant on third-party data, and instead use Privacy Enhancing
          Technologies (PETs). These PETs protect data in new ways and improve
          transparency and user control. Based in cryptography and mathematics,
          these technologies are highly technical and often misunderstood in the
          industry.
        </p>
        <p>
          Craig Walker worked with Meta to develop communications tools to
          explain these PETs, giving advertisers, regulators and policy-makers
          an understanding of how they work to protect and increase data
          privacy.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="privacy-tech-1.png" />
        </WorkImage>

        <Numbers>
          <Number number={3} text="Communication tools developed" />
          <Number number={7} text="Cryptographers consulted" />
          <Number number={12} text="Weeks from design to launch" />
        </Numbers>

        <WorkTextBlock>
          <h2>Capturing tales from the cryptographers</h2>
          <p>
            Working closely with the Meta team of data scientists and
            cryptographers, we designed separate digital explainers for three
            PETs: Multi-party Computation, In-device Learning and Differential
            Privacy. The narratives and content for each were built from the
            explanations, stories and experiences of the data scientists and
            cryptographers in sharing the work they do.
          </p>
        </WorkTextBlock>

        <WorkImage transparent>
          <Image imgName="privacy-tech-2.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>From storyboards, to stories</h2>
          <p>
            We worked closely with the Meta team to develop the PET explainers.
            In weekly workshops, we delved into the details of these
            technologies and then took a step back to see how we could explain
            it to non-technical audiences. The Meta PET team shared how they
            explain their work to friends and family, which uncovered powerful
            analogies and simplified language. Together, we iterated and refined
            storyboards and narratives until they were technically correct as
            well as compelling for an everyday reader.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="privacy-tech-3.png" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Making complex technologies relevant</h2>
          <p>
            Not only did we need to make the technology easy to grasp and relay,
            it was important that they show their impact and value in relevant
            and relatable ways. We developed storylines and characters (based on
            Meta’s new illustration guidelines) to reflect the types of users
            and industries that would resonate with advertisers, regulators and
            policy-makers. This has gained traction from internal and external
            stakeholders alike, who have praised the work for its brevity and
            simplicity in explaining complex technologies.
          </p>
        </WorkTextBlock>
        <WorkImage>
          <Video videoType="video/mp4" videoSrc={vid} />
        </WorkImage>
        <WorkImage transparent>
          <Image imgName="privacy-tech-5.png" />
        </WorkImage>
        <GetInTouch dataView="MakeReal" />
        <RelatedWork exclude="pet-meta" />
      </ContentHolder>
    </>
  )
}

export default PrivacyTech
